import { getCurrentUser, userSigninSuccess } from "@nerdjs/account-kit";
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux";
import { store } from "./store";
import { AppConfig } from "../environement";
import { showNotification } from "@nerdjs/nerd-ui";
import {
  createTimerSuccess,
  getCurrentTimer,
  getTimers,
  updateTimerSuccess,
} from "@nerdjs/kronos-kit";

export const mainMiddleware: Middleware =
  (api: MiddlewareAPI<Dispatch<AnyAction>, unknown>) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    // Do stuff
    console.log(`[mainMiddleware]: mainMiddleware sees action:`, api);
    console.log(action);

    if (
      action.type == createTimerSuccess ||
      action.type == updateTimerSuccess
    ) {
      api.dispatch(getTimers());
    }

    if (action.type === "ApiErrorAction") {
      const {
        uuid,
        status,
        description = "",
        message = "",
      } = action.payload.error;

      if (status === 401) {
        if (
          action.payload.originAction.payload.request.url ===
          AppConfig.api.currentUser
        ) {
          store.dispatch(userSigninSuccess(undefined));
        } else {
          store.dispatch(getCurrentUser());
        }
      }

      if (status === 401) {
        window.location.replace(AppConfig.front.baseHostname);
      }

      if (
        action.payload.originAction.payload.request.url !==
        AppConfig.api.currentUser
      )
        if (
          action.payload.originAction.type != getCurrentTimer &&
          status === 403
        )
          store.dispatch(
            showNotification({
              notification: {
                uuid: uuid,
                title: `${status} - ${description}`,
                body: message,
                severity: status > 300 && status < 500 ? "warning" : "error",
                autohide: true,
              },
            })
          );
    }

    return next(action);
  };
