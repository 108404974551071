import { Box } from "@mui/material";
import { getTasks, TaskForm, TasksDataGrid } from "@nerdjs/kronos-kit";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

/**
 *
 * @returns {ReactElement} Tasks page
 */
export function Tasks() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTasks());
  }, []);

  return (
    <Box sx={{ p: 2, display: "flex", flex: 1 }}>
      <TasksDataGrid />
      <TaskForm />
    </Box>
  );
}
