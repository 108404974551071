import { useColorScheme } from "@mui/joy";
import {
  AppBar,
  Button,
  ButtonProps,
  Divider,
  Hidden,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuProps,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { alpha, styled, SxProps, useTheme } from "@mui/system";
import * as React from "react";
import { Link } from "react-router-dom";
import { font } from "../../constants";
import { RouterConfig } from "../config/routerConfig";
import { useAppSelector } from "../hooks";

export type NerdAppBarProps = {
  title?: string;
  logo?: string | string[];
  variant?: "dense" | "regular";
  content?: Array<NerdAppBarContent | JSX.Element>;
};

export type NerdAppBarContent = {
  title?: string;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  variant?: "text" | "outlined" | "contained";
  size?: "small" | "medium" | "large";
  sx?: SxProps<Theme>;
  nested?: Array<NerdAppBarNestedContent>;
  divider?: boolean;
  href?: string;
  callback?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export type NerdAppBarNestedContent = {
  title?: string;
  subtitle?: string;
  icon?: JSX.Element | string;
  divider?: boolean;
  href?: string;
};

/**
 * @component
 * @param {NerdAppBarProps} props NerdAppBar props
 * @param {string} props.title title of the app
 * @param {string} props.logo Logo of the app
 * @param {string} props.variant Size of the appBar (dense or regular)
 * @param {Array<NerdAppBarContent | React.ReactElement>} props.content Height of the appbar. Could be dense or regular.
 * @returns {React.ReactElement | null} UserDisplay component
 */
export function NerdAppBar({
  title,
  logo,
  content = [],
  variant = "regular",
}: NerdAppBarProps): React.ReactElement | null {
  const logged = useAppSelector(
    (state) => state.accountKitState.userState.currentUser
  );

  const { mode } = useColorScheme();

  if (!logged) return null;

  let _logo = logo?.length ? logo[0] : logo;
  if (mode === "dark" && (logo?.length ?? 0) == 2) _logo = logo![1];

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        background: (theme) => theme.palette.background.default,
      }}
      elevation={0}
      color="transparent"
    >
      <Toolbar variant={variant}>
        <Button
          href={RouterConfig.root}
          sx={{ color: "text.primary", marginLeft: -2 }}
        >
          <img
            alt="logo"
            style={{
              paddingRight: 6,
            }}
            src={_logo as string}
            height={variant === "dense" ? "30px" : "45px"}
          />
          <Hidden smDown>
            <Typography sx={{ fontFamily: font, fontSize: 24 }}>
              {title?.toUpperCase()}
            </Typography>
          </Hidden>
        </Button>
        {/* <Divider sx={{ m: 1 }} orientation="vertical" flexItem /> */}
        {React.Children.toArray(
          content.map((c) => {
            if (isNerdAppBarContent(c)) return <NerdAppBarMenuItem {...c} />;
            return c;
          })
        )}
      </Toolbar>
      <Divider />
    </AppBar>
  );
}

/**
 *
 * @param {NerdAppBarContent | React.ReactElement} toTest element you want to know if it has the NerdAppBarContent type
 * @returns {boolean} is an NerdAppBarContent or not
 */
function isNerdAppBarContent(
  toTest: NerdAppBarContent | React.ReactElement
): toTest is NerdAppBarContent {
  return (toTest as NerdAppBarContent).title !== undefined;
}

/**
 * @param {NerdAppBarContent} c Content of the menu item
 * @returns {React.ReactElement | null} the menu
 */
export function NerdAppBarMenuItem(
  c: NerdAppBarContent
): React.ReactElement | null {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!c) {
    return null;
  }

  let buttonProps: ButtonProps | { component: unknown; to: string } = {
    onClick: c.nested ? handleClick : c.callback,
  };

  if (c.href) {
    buttonProps = {
      component: Link,
      to: c.href,
    };
  }

  return (
    <>
      {c.divider ? (
        <Divider sx={{ m: 1 }} orientation="vertical" flexItem />
      ) : (
        []
      )}
      {md ? (
        <Button {...c} {...buttonProps}>
          {c.title}
        </Button>
      ) : (
        <Tooltip title={c.title ? c.title : ""}>
          <IconButton {...c} color="primary" size="small" {...buttonProps}>
            {c.startIcon}
          </IconButton>
        </Tooltip>
      )}
      {c.nested ? (
        <StyledMenu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {c.nested.map((n, i) => {
            return (
              <ListItemButton
                component={Link}
                to={n.href ? n.href : RouterConfig.root}
                dense
                divider={n.divider}
                key={i}
              >
                {typeof n.icon === "string" ? (
                  <div className={"faicon"}>
                    <i className={n.icon}></i>
                  </div>
                ) : (
                  <ListItemIcon>{n.icon}</ListItemIcon>
                )}
                <ListItemText primary={n.title} secondary={n.subtitle} />
              </ListItemButton>
            );
          })}
        </StyledMenu>
      ) : (
        []
      )}
    </>
  );
}

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: 10,
    background:
      theme.palette.mode === "light" ? "white" : theme.palette.grey[900],
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
