import { accountKitReducer } from "@nerdjs/account-kit";
import { kronosMiddleware, kronosReducer } from "@nerdjs/kronos-kit";
import { networkMiddleware, networkReducer } from "@nerdjs/nerd-network";
import { notificationReducer } from "@nerdjs/nerd-ui";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { mainMiddleware } from "./mainMiddleware";

export const store = createStore(
  combineReducers({
    // this defines the shape of our MainState
    accountKitState: accountKitReducer,
    notification: notificationReducer,
    networkState: networkReducer,
    kronosState: kronosReducer,
  }),
  applyMiddleware(mainMiddleware, kronosMiddleware, networkMiddleware)
);

export type AppDispatch = typeof store.dispatch;
