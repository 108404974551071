import { AppConfig } from "../../environement";
import { NerdAppBarProps } from "../nerdAppBar/nerdAppBar";
import { NerdAppBarDarkModeButton } from "../nerdAppBarDarkModeButton/nerdAppBarDarkModeButton";
import NerdAppsMenuWrapper from "../nerdAppsMenuWrapper/nerdAppsMenuWrapper";
import { NerdUserMenu } from "../nerdUserMenu/nerdUserMenu";

/**
 * @param {ThemeOptions} theme App Theme
 * @returns {NerdAppBarProps} NerdAppBarProps
 */
export const appBarConfig: NerdAppBarProps = {
  variant: "dense",
  title: AppConfig.app.title,
  logo: [AppConfig.app.logo, AppConfig.app.logoDarkMode],
  content: [
    <div style={{ flexGrow: 1 }} key={"nerdAppMenustylingDiv"} />,
    <NerdAppsMenuWrapper key={"NerdAppsMenuWrapper"} />,
    <NerdAppBarDarkModeButton key={"nerdAppBarDarkModeButton"} />,
    <NerdUserMenu key={"myAccountButton"} />,
  ],
};
