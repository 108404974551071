import { Box } from "@mui/material";
import { TimersDataGrid, KronosTimerDialog } from "@nerdjs/kronos-kit";

/**
 *
 * @returns {ReactElement} Timers page
 */
export function Timers() {
  return (
    <Box sx={{ p: 2, display: "flex", flex: 1 }}>
      <TimersDataGrid />
      <KronosTimerDialog />
    </Box>
  );
}
