import {
  Box,
  CircularProgress,
  CssBaseline,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { getCurrentUser } from "@nerdjs/account-kit";
import {
  initNetworkAction,
  withNetworkCompletionDispatch,
} from "@nerdjs/nerd-network";
import { clearNotification, NerdNotifier } from "@nerdjs/nerd-ui";
import {
  ReactChild,
  ReactChildren,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from "react";

import { CssVarsProvider } from "@mui/joy";
import { AppRouter } from "../../appRouter";
import { AppConfig } from "../../environement";
import { appBarConfig } from "../config/appBarConfig";
import { useAppDispatch, useAppSelector } from "../hooks";
import { MainDrawer } from "../mainDrawer/mainDrawer";
import { NerdAppBar } from "../nerdAppBar/nerdAppBar";
import styles from "./style.module.scss";
import { getTheme } from "../config/themeConfig";

/**
 * App wrapper
 *
 * @returns {ReactElement} App wrapper
 */
export default function NerdApp(): ReactElement {
  const dispatch = useAppDispatch();
  const muiTheme = useTheme();
  const isDownSm = useMediaQuery(muiTheme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const networkCompletionStack = useAppSelector(
    (state) => state.networkState.networkCompletionStack
  );
  const impersonateSuccess = useAppSelector(
    (state) => state.accountKitState.userState.impersonateSuccess
  );
  const [requestPending, setRequestPending] = useState(true);
  const [requestUUID, setRequestUUID] = useState("");

  useEffect(() => {
    if (
      requestUUID !== "" &&
      !networkCompletionStack.some((e) => e[requestUUID])
    ) {
      setRequestPending(false);
    }
  }, [requestUUID, networkCompletionStack]);

  useEffect(() => {
    dispatch(initNetworkAction(AppConfig.api));
  }, []);

  useEffect(() => {
    setRequestPending(true);
    const uuid = withNetworkCompletionDispatch(dispatch, getCurrentUser());
    setRequestUUID(uuid);
  }, [impersonateSuccess]);

  const theme = getTheme();

  return (
    <CssVarsProvider theme={theme}>
      <NotifierWrapper>
        <Box
          sx={{
            backgroundImage: `url("${AppConfig.app.rootBackground}") `,
            display: "flex",
            flex: 1,
            flexDirection: isDownSm ? "column" : "row",
          }}
        >
          <CssBaseline />
          {requestPending ? (
            <Grid className={styles.circularProgressContainer} container>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <>
              <NerdAppBar {...appBarConfig} />
              <MainDrawer />
              <Box
                component="main"
                sx={{
                  flexGrow: 1,
                  display: "flex",
                }}
              >
                <AppRouter />
              </Box>
            </>
          )}
        </Box>
      </NotifierWrapper>
    </CssVarsProvider>
  );
}

const NotifierWrapper = ({
  children,
}: {
  children: (ReactChild | ReactChildren) & ReactNode;
}) => {
  const dispatch = useAppDispatch();
  const notification = useAppSelector((s) => s.notification);
  return (
    notification && (
      <NerdNotifier
        {...notification}
        onClose={() => {
          dispatch(clearNotification());
          notification.onClose?.();
        }}
      >
        {children}
      </NerdNotifier>
    )
  );
};
