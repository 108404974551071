import { Box } from "@mui/material";
import { getUsers, UserForm, UsersDataGrid } from "@nerdjs/kronos-kit";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

/**
 *
 * @returns {ReactElement} Users page
 */
export function Users() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  return (
    <Box sx={{ p: 2, display: "flex", flex: 1 }}>
      <UsersDataGrid />
      <UserForm />
    </Box>
  );
}
