import { Box } from "@mui/material";
import { getProjects, ProjectForm, ProjectsDataGrid } from "@nerdjs/kronos-kit";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

/**
 *
 * @returns {ReactElement} Projects page
 */
export function Projects() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProjects());
  }, []);

  return (
    <Box sx={{ p: 2, display: "flex", flex: 1 }}>
      <ProjectsDataGrid />
      <ProjectForm />
    </Box>
  );
}
