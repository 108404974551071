import { Box, BoxProps, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { RouterConfig } from "./hooks/config/routerConfig";
import { useAppSelector } from "./hooks/hooks";
import { Me } from "./routes/me";
import NotFoundRoute from "./routes/notFoundRoute";
import { Projects } from "./routes/projects";
import { Tasks } from "./routes/tasks";
import { Timers } from "./routes/timers";
import { Users } from "./routes/users";

const BoxWrapper = styled((props: { hideMarginTop?: boolean } & BoxProps) => (
  <Box {...props} />
))(({ theme, hideMarginTop }) => ({
  flex: 1,
  display: "flex",
  marginTop:
    useMediaQuery(theme.breakpoints.down("sm")) || hideMarginTop
      ? "inherit"
      : "48px",
  background:
    theme.palette.mode !== "light"
      ? "rgba(0,0,0,0.9)"
      : "rgba(255,255,255,0.9)",
  backdropFilter: "blur(10px)",
  flexGrow: 1,
}));

/**
 *
 * @returns {React.ReactElement} the router
 */
export function AppRouter(): React.ReactElement {
  const currentUser = useAppSelector(
    (state) => state.accountKitState.userState.currentUser
  );
  return (
    <Routes>
      <Route
        path={RouterConfig.root}
        element={<Navigate to={RouterConfig.me} />}
      ></Route>
      <Route
        path={RouterConfig.me}
        element={
          <BoxWrapper>
            <Me />
          </BoxWrapper>
        }
      ></Route>
      <Route
        path={RouterConfig.timers}
        element={
          <BoxWrapper>
            <Timers />
          </BoxWrapper>
        }
      ></Route>
      <Route
        path={RouterConfig.projects}
        element={
          <BoxWrapper>
            <Projects />
          </BoxWrapper>
        }
      ></Route>
      <Route
        path={RouterConfig.tasks}
        element={
          <BoxWrapper>
            <Tasks />
          </BoxWrapper>
        }
      ></Route>
      <Route
        path={RouterConfig.users}
        element={
          <BoxWrapper>
            <Users />
          </BoxWrapper>
        }
      ></Route>
      <Route
        path={"*"}
        element={
          <BoxWrapper hideMarginTop={currentUser ? false : true}>
            <NotFoundRoute />
          </BoxWrapper>
        }
      />
    </Routes>
  );
}
