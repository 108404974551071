import { Box } from "@mui/material";
import { CurrentUserSelector } from "@nerdjs/account-kit";
import {
  getTimersByFilter,
  getUsers,
  KronosTimer,
  KronosTimerDialog,
  latestUncompletedTimerSelector,
  Timer,
  TimersDataGrid,
  timersSelector,
} from "@nerdjs/kronos-kit";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../hooks/hooks";

/**
 *
 * @returns {ReactElement} Me page
 */
export function Me() {
  const dispatch = useDispatch();
  const timers: Timer[] = useAppSelector(timersSelector);
  const latestTimer = useAppSelector(latestUncompletedTimerSelector);
  const me = useAppSelector(CurrentUserSelector);

  useEffect(() => {
    // if (me) dispatch(getTimers());
    dispatch(getUsers());
    dispatch(
      getTimersByFilter({
        filters: JSON.stringify([
          {
            name: "timers.userUUID",
            value: me?.uuid,
            comparison: "eq",
          },
        ]),
      })
    );
  }, [me]);

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        flex: 1,
        gap: 2,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <KronosTimer helmet />
      </Box>
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        <TimersDataGrid />
      </Box>
      <KronosTimerDialog />
    </Box>
  );
}
